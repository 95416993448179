import React from "react";
import "./cardItem.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";

const CardItem = ({
  name,
  location,
  images,
  map_id,
  rating,
  facility,
  ...rest
}) => {
  const { loading } = useSelector((state) => state.GetHotel);

  return (
    <>
      <div {...rest} style={{ cursor: "pointer" }}>
        <hr />
        {loading ? (
          <Skeleton count={1} width={200} />
        ) : (
          <div
            className="d-flex justify--start name-detail"
            style={{
              fontSize: 17,
              fontWeight: '500',
              marginTop:-12,
              marginLeft: 15,
              fontFamily: "Poppins",
              marginRight: 10,
            }}
          >
            {name}
          </div>
        )}
        {loading ? (
          <Skeleton count={1} width={200} />
        ) : (
          <div
            className="text-dark"
            // style={{ fontFamily: "Poppins", fontWeight: 600 }}
          >
            <i
              className="fas fa-map-marker-alt loc-detail ml-3"
              style={{ color: "#aaa", fontSize: 18, marginRight: 30 }}
            >
              <div
                className="d-flex justify--start name-detail"
                style={{
                  fontSize: 14,
                  fontWeight: '400',
                  marginLeft: 20,
                  fontFamily: "Poppins",
                  textAlign:'justify',
                  marginTop: -18,
                }}
              >
                {location}
              </div>
            </i>
          </div>
        )}
      
      </div>
    </>
  );
};

export default CardItem;
