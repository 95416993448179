import { useState, useEffect } from "react";
import { useMedia } from "use-media";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import FlatList from "flatlist-react";
import Carousel from "react-elastic-carousel";
import { GetById } from "../../../config/Redux/action/getById";
import { Gap, MapDetail, useGeolocation } from "../../../component";
import "./detailPlaceMobile.css"


const DetailPlaceMobile = (props) => {

  const { data }            = useSelector((state) => state.GetById);
  const [modal, setModal]   = useState(false)
  const [detail, setDetail] = useState("")
  const isMobile            = useMedia({ minWidth: "768px" })
  const dispatch            = useDispatch();
  const history             = useHistory();
  const location            = useGeolocation();
  const showDetail          = () => setDetail(!detail);
  const certificate         = data.certificate;

  // measure distance between gps & location
  const lat1 = parseFloat(location.lat);
  const lon1 = parseFloat(location.lng);
  const lat2 = parseFloat(data.latitude);
  const lon2 = parseFloat(data.longitude);
  const R    = 6371e3; // earth radius in meter
  
  const φ1 = lat1 * (Math.PI / 180);
  const φ2 = lat2 * (Math.PI / 180);
  const Δφ = (lat2 - lat1) * (Math.PI / 180);
  const Δλ = (lon2 - lon1) * (Math.PI / 180);
  
  const a =
  Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
  Math.cos(φ1) * Math.cos(φ2) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2));
  
  const c        = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = parseInt((R * c) / 1000);

  useEffect(() => {
    if (window.innerWidth > 767) return history.replace(`/detail_place/${props.match.params.map_id}`)
  }, [])

  useEffect(() => { 
    if (!modal) setModal(isMobile)
  }, [isMobile])

  // variable facilities
  const facility = (items) => {
    return (
      <div className="facility">
        <img alt="items" src={items} width={20} height={20} />
      </div>
    );
  };

  return (
    <>
      {/* <MapDetail map_id={data.map_id} /> */}
      <div className={detail? "hide-map" : "map"}>
        <div className="map-mobile">
          <MapDetail map_id={data.map_id} />
        </div>
        <div className="view-info">
          <div className="view-wrap" onClick={showDetail}>
            {data.photo ? (
              <img className="img-place-mobile" alt="detail"
                src={data.photo}
              />
            ): null}
            <Gap height={5} />
            <h6 className="d-flex justify--start name-place">
              {data.name}
            </h6>
            
            <Gap height={3} />
            <div className="ml-2">
              <FlatList list={data.facility} renderItem={facility} />
            </div>
          </div>
        </div>
      </div>
      <div onClick={showDetail} className={detail ? "detail-active-company-mobile" : "map"}>
      
        <img className="img-place-detail" alt="detail"
          src={data.photo}
        />
        

        <Gap height={10} />
        <h6 className="d-flex justify--start name-place">
          {data.name}
        </h6>

        <Gap height={5} />
        <div className="ml-2">
          <FlatList list={data.facility} renderItem={facility} />
        </div>

        <Gap height={5} />
        <h6 className="d-flex justify--start info-data">
          {data.body}
        </h6>
        
        <hr />

        <Scrollbars style={{ height: "55vh" }}>
          <h6 className="text-dark d-flex">
            {distance ? (
              <>
                <i className="fas fa-map-pin icon-data"/>
                <h6 className="d-flex justify--start info-data">
                  {`${distance} km from your location`}
                </h6>
              </>
            ) : null}
          </h6>

          <h6 className="text-dark d-flex">
            {data.location ? (
              <>
                <i className="fas fa-map-marker-alt icon-data"/>
                <h6 className="d-flex justify--start info-data">
                  {data.location}
                </h6>
              </>
            ) : null}
          </h6>

          <h6 className="text-dark d-flex">
              {data.website ? (
                <>
                  <i className="fas fa-globe icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {data.website}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.phone1 ? (
                <>
                  <i className="fas fa-phone-alt icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {data.phone1}
                  </h6>
                </>
              ) : null}
            </h6>

            <Gap height={10}/>
            {certificate && certificate.length ? (
              <Carousel>
                {certificate && certificate.length
                  ? certificate.map((item) => {
                    return (
                      <div>
                        <img
                          alt="certificate"
                          className="img-certificate"
                          src={item.certificate_file}
                        />
                        <h6 className="certif-data">
                          {item.certificate_name}
                        </h6>
                        <h6 className="certif-data">
                          {item.certificate_date}
                        </h6>
                      </div>
                    );
                  })
                : null}
              </Carousel>
              
            ) : null}
        </Scrollbars>
        <div onClick={showDetail} className="btn-close">
          <p className="title-close">Close</p>
        </div>
      </div>
    </>
  )

}

export default DetailPlaceMobile
