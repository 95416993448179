import { useState, useEffect } from "react";
import { useMedia } from "use-media";
import { withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FlatList from "flatlist-react";
import { Modal, Button } from "react-bootstrap";
import Carousel from "react-elastic-carousel";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { MapDetail, Navbar, useGeolocation } from "../../component";
import { Gap } from "../../component/atom";
import "./detailPlace.css";
import { GetById } from "../../config/Redux/action/getById";

const DetailPlace = (props) => {
  const { data }          = useSelector((state) => state.GetById);
  const [show, setShow]   = useState(false);
  const [modal, setModal] = useState(false)
  const dispatch          = useDispatch();
  const location          = useGeolocation();
  const history           = useHistory();
  const handleClose       = () => setShow(false);
  const handleShow        = () => setShow(true);
  const certificate       = data.certificate;
  const mobileView        = useMedia({ maxWidth: "767px" })

  // measure distance between gps & location
  const lat1 = parseFloat(location.lat);
  const lon1 = parseFloat(location.lng);
  const lat2 = parseFloat(data.latitude);
  const lon2 = parseFloat(data.longitude);
  const R    = 6371e3; // earth radius in meter
  
  const φ1 = lat1 * (Math.PI / 180);
  const φ2 = lat2 * (Math.PI / 180);
  const Δφ = (lat2 - lat1) * (Math.PI / 180);
  const Δλ = (lon2 - lon1) * (Math.PI / 180);
  
  const a =
  Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
  Math.cos(φ1) * Math.cos(φ2) * (Math.sin(Δλ / 2) * Math.sin(Δλ / 2));
  
  const c        = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = parseInt((R * c) / 1000);
  const map_id   = props.match.params.map_id;
  
  useEffect(() => {
    dispatch(
      GetById({
        map_id:  props.match.params.map_id,
      })
    );
    if (window.innerWidth <= 767) return history.replace(`/m/detail_place/${props.match.params.map_id}`)
  }, []);

  useEffect(() => {
    
    if (!modal) setModal(mobileView)
  }, [mobileView])

  // variable facilities
  const facility = (items) => {
    return (
      <div className="facility">
        <img alt="items" src={items} width={20} height={20} />
      </div>
    );
  };

  return (
    <>
      <div className="main-page">
        {/* info detail version desktop */}
        <div className="left">
          <Scrollbars>
            <div className="navbar">
              <Navbar className="category" />
            </div>

            <div className="img-wrap">
              <img className="img-place" alt="detail"
                src={data.photo}
              />
            </div>
            
            <Gap height={10} />
            <h6 className="d-flex justify--start name-place">
              {data.name}
            </h6>
    
            <Gap height={5} />
            <div className="ml-2">
              <FlatList list={data.facility} renderItem={facility} />
            </div>
            
            <Gap height={15} />
            <h6 className="d-flex justify--start info-data">
              {data.body}
            </h6>
            
            <hr />
            
            <h6 className="text-dark d-flex">
              {distance ? (
                <>
                  <i className="fas fa-map-pin icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {`${distance} km from your location`}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.location ? (
                <>
                  <i className="fas fa-map-marker-alt icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {data.location}
                  </h6>
                </>
              ) : null}
            </h6>
            
            <h6 className="text-dark d-flex">
              {data.website ? (
                <>
                  <i className="fas fa-globe icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {data.website}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.phone1 ? (
                <>
                  <i className="fas fa-phone-alt icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    {data.phone1}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.luas_kebun ? (
                <>
                  <i className="fas fa-tree icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    Garden Area : {data.luas_kebun}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.luas_tertanam ? (
                <>
                  <i className="fas fa-h-square icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    Planted Area : {data.luas_tertanam}
                  </h6>
                </>
              ) : null}
            </h6>

            <h6 className="text-dark d-flex">
              {data.stok_kelapa_sawit ? (
                <>
                  <i className="fas fa-cubes icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    Available Stock : {data.stok_kelapa_sawit}
                  </h6>
                </>
              ) : null}
            </h6>
            
            <h6 className="text-dark d-flex">
              {data.usia_tanaman ? (
                <>
                  <i className="fas fa-leaf icon-data"/>
                  <h6 className="d-flex justify--start info-data">
                    Plant Age : {data.usia_tanaman}
                  </h6>
                </>
              ) : null}
            </h6>
            

            <Gap height={15} />

            {/* button & modal certificate */}
            {certificate && certificate.length ? (
              <div>
                <div className="ml-4">
                  <Button className="button-modal" 
                    onClick={handleShow}>
                    Certificate
                  </Button>
                </div>
                <Modal className="text-center"
                  show={show}
                  onHide={handleClose}
                  animation={false}>
                  <Modal.Header closeButton>
                    <Modal.Title>Certificate</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div>
                      <h5 className="text-dark name-place">{data.name}</h5>
                    </div>
                    <Carousel>
                      {certificate && certificate.length
                        ? certificate.map((item) => {
                            return (
                              <div>
                                <img
                                  alt="detail certificate"
                                  className="img-certificate"
                                  src={item.certificate_file}
                                />
                                <h6 className="text-dark mt-1 info-data">
                                  {item.certificate_name}
                                </h6>
                                <p className="text-dark info-data">
                                  {item.certificate_date}
                                </p>
                              </div>
                            );
                          })
                        : null}
                    </Carousel>
                  </Modal.Body>
                </Modal>
              </div>
            ) : null}
          </Scrollbars>
        </div>

        {/* map */}
        <div className="right">
          <div className="maps">
            <MapDetail map_id={data.map_id}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(DetailPlace);