//url goggle map
export const mapMarker = "https://cdn0.iconfinder.com/data/icons/small-n-flat/24/678111-map-marker-512.png";
// export const mapAccessToken = "pk.eyJ1IjoicmFuaXN1cCIsImEiOiJja20xc2d5NjkwbHI0MnZtOXYxdWhqOWF3In0.a7X_gbwaNm9akUyRhjVzRw";
// export const mapStyle = "mapbox://styles/mapbox/streets-v11";
export const styleMap = "https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyCmsJhjoklKf4U5hzX1iqTWK-0rJ02Y1nk&center=47.65,-122.35&zoom=12&format=png&maptype=roadmap&size=480x360"
export const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCmsJhjoklKf4U5hzX1iqTWK-0rJ02Y1nk`

//url marker
export const markerUser = `https://image.flaticon.com/icons/png/128/1673/1673221.png`
export const markerHotel = `https://assets.labelmaps.com/markers/hotel.png`
export const markerRestaurant = `https://assets.labelmaps.com/markers/restaurant.png`
export const markerSPBU = `https://assets.labelmaps.com/markers/spbu.png`
export const markerCompany = `https://assets.labelmaps.com/markers/company.png`

//url api
// export const URI = "http://localhost:8080/map";
// export const getTotalPerusahaanPerprovinsi = "http://localhost:8080/api/map-chart-total-perusahaan-perprovinsi";
export const URI = "https://api.labelmaps.com/map";
export const URIChart = "https://api.labelmaps.com";
export const URISearch = 'https://api.labelmaps.com/search'