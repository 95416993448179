import { useState, Fragment } from "react";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";
import "./mapsHotel.css";
import { useSelector } from "react-redux";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { CHSE } from "../../../assets";
import { useHistory } from "react-router-dom";
import { useGeolocation } from "../../../component";
import { googleMapURL, markerHotel, markerUser, styleMap } from "../../../utils";
import Carousel from "react-elastic-carousel";


export default function MapHotel({ map_id, nearby }) {
  const { data }                          = useSelector((state) => state.GetHotel);
  const [selectedPlace, setSelectedPlace] = useState(null);
  const history                           = useHistory();
  const location                          = useGeolocation();

  // variable map
  const MapWrapped = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={11.5}
        defaultCenter={{
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng)
        }}
        defaultOptions={{
          styles: styleMap,
        }}
        options={{ gestureHandling: 'greedy'}}
      >
        <Fragment>
          {/* marker user location */}
          <Marker
            position={{
              lat: parseFloat(location.lat),
              lng: parseFloat(location.lng)
            }}
            icon={{
              url: markerUser,
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          />

          {/* check LatLng nearby from user location */}
          {nearby
            ? data.map((label) => {
                const lat1 = parseFloat(location.lat);
                const lon1 = parseFloat(location.lng);
                const lat2 = parseFloat(label.latitude);
                const lon2 = parseFloat(label.longitude);
                const R    = 6371e3; // earth radius in meter

                const φ1 = lat1 * (Math.PI / 180);
                const φ2 = lat2 * (Math.PI / 180);
                const Δφ = (lat2 - lat1) * (Math.PI / 180);
                const Δλ = (lon2 - lon1) * (Math.PI / 180);

                const a =
                  Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                  Math.cos(φ1) *
                    Math.cos(φ2) *
                    (Math.sin(Δλ / 2) * Math.sin(Δλ / 2));

                const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                let markerPlace = [];
                const distance = (R * c) / 1000;
                markerPlace.push(distance);
                if (markerPlace < 10) {
                  return (
                    <Marker
                      position={{
                        lat: parseFloat(label.latitude),
                        lng: parseFloat(label.longitude),
                      }}
                      onClick={() => {
                        setSelectedPlace(label);
                      }}
                      icon={{
                        url: markerHotel,
                        scaledSize: new window.google.maps.Size(25, 35),
                      }}
                    />
                  );
                }
              })
            : data.map((label) => {
                return (
                  <Marker
                    position={{
                      lat: parseFloat(label.latitude),
                      lng: parseFloat(label.longitude),
                    }}
                    onClick={() => {
                      setSelectedPlace(label);
                    }}
                    icon={{
                      url: markerHotel,
                      scaledSize: new window.google.maps.Size(25, 35),
                    }}
                  />
                );
              })}

          {/* popup info */}
          {selectedPlace && (
            <InfoWindow
              onCloseClick={() => {
                setSelectedPlace(null);
              }}
              position={{
                lat: parseFloat(selectedPlace.latitude),
                lng: parseFloat(selectedPlace.longitude),
              }}
            >
              <div className="bg-popup">
                <img alt="photomarker" className="img-popup"
                  src={selectedPlace.photo}
                />
                <h2 className="name-popup mx-3">
                  {selectedPlace.name}
                </h2>
                {selectedPlace.phone1 ? (
                  <div className="d-flex part mt-2">
                    {selectedPlace.phone1 ? (
                      <span className="phone-popup"><i className="fas fa-phone-alt mr-2"/> {selectedPlace.phone1} </span>

                    ): null}
                    <div className="category-popup">
                      Hotel
                    </div>
                  </div>
                ) : (
                  <div className="d-flex part-2 mt-2">
                    {selectedPlace.phone1 ? (
                      <i className="fas fa-phone-alt phone-popup"> {selectedPlace.phone1}</i>
                    ): null}
                    <div className="category-popup">
                      Hotel
                    </div>
                  </div>
                )}
                {selectedPlace.certificate.length > 0 ? (
                  <div className="part mt-2">
                    <Carousel>
                      {selectedPlace.certificate && selectedPlace.certificate.length
                        ? selectedPlace.certificate.map((item) => {
                            return (
                              <div>
                                <img
                                  alt="detail certificate"
                                  className="logo-popup-company mt-2 mb-3"
                                  src={item.certificate_file}
                                />
                                {/* <h6 className="text-dark mt-1 info-data">
                                  {item.certificate_name}
                                </h6>
                                <p className="text-dark info-data">
                                  {item.certificate_date}
                                </p> */}
                              </div>
                            );
                          })
                        : null}
                    </Carousel>
                    <div className="btn-detail"
                        onClick={() =>
                          history.push(`/detail_place/${selectedPlace.map_id}`)
                        }>
                        More Detail
                    </div>
                  </div>
                ) : (
                  <div className="d-flex part-2 mt-2">
                    {selectedPlace.certificate.length > 0 ? (
                      <div>
                        <img
                          src={CHSE}
                          alt="icon CHSE"
                          width={"100%"}
                          height={25}
                        />
                      </div>
                    ) : null}
                    <div className="btn-detail"
                      onClick={() =>
                        history.push(`/detail_place/${selectedPlace.map_id}`)
                      }>
                      More Detail
                    </div>
                  </div>
                )}
                
              </div>
            </InfoWindow>
          )}
        </Fragment>
      </GoogleMap>
    ))
  );

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <MapWrapped
        map_id={map_id}
        nearby={nearby}
        googleMapURL={googleMapURL}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    </div>
  );
}
