import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Restaurant from "../Restaurant";
import Dashboard from "../Dashboard";
import Welcome from "../Welcome";
import Hotel from "../Hotel";
import DetailPlace from "../DetailPlace";
import Company from "../Company";
import CompanyMobile from "../Mobile/CompanyMobile";
import HotelMobile from "../Mobile/HotelMobile";
import RestaurantMobile from "../Mobile/RestaurantMobile";
import DetailPlaceMobile  from "../Mobile/DetailPlaceMobile";
// import SPBU from "../SPBU";

const MainApp = () => {
  return (
    <Router>
      <Switch>
        {/* mobile version */}
        <Route path={"/m/detail_place/:map_id"}>
          <DetailPlaceMobile/>
        </Route>
        <Route path={"/m/restaurant"}>
          <RestaurantMobile/>
        </Route>
        <Route path={"/m/hotel"}>
          <HotelMobile/>
        </Route>
        <Route path={"/m/company"}>
          <CompanyMobile/>
        </Route>
        
        {/* web version */}
        <Route path="/detail_Place/:map_id">
          <DetailPlace />
        </Route>
        {/* <Route path="/SPBU">
          <SPBU />
        </Route> */}
        <Route path="/hotel">
          <Hotel />
        </Route>
        <Route path="/company">
          <Company />
        </Route>
        <Route path="/restaurant">
          <Restaurant />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/">
          <Welcome />
        </Route>

      </Switch>
    </Router>
  );
};

export default MainApp;
