import { useState, useEffect } from "react";
import { useMedia } from "use-media";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Skeleton from 'react-loading-skeleton';
import {
  CardItem,
  HeaderNav, 
  Navbar,
  MapCompany,
  useGeolocation,
} from "../../component";
import { Gap, CustomInput } from "../../component/atom";
import "./company.css";
import {
  FilterCompany,
  SearchCompany,
} from "../../config/Redux/action/getCompany";

const Company = () => {
  const { data, loadingCompany }    = useSelector((s) => s.GetCompany)
  const [search, setSearch]         = useState("");
  const [listMenu, setListMenu]     = useState("");
  const [nearby, setNearby]         = useState("");
  const [modal, setModal]           = useState(false)
  const [style, setStyle]           = useState("input-company");
  const [nameFocused, setNameFocused] = useState(false)
  const showListMenu                = () => setListMenu(!listMenu);
  const showNearby                  = () => setNearby(!nearby);
  const history                     = useHistory();
  const dispatch                    = useDispatch();
  const location                    = useGeolocation();
  const mobileView                  = useMedia({ maxWidth: "767px" })

  // console.log('dataa', data)


  //search
  // const onSubmit = (e) => {
  //   e.preventDefault(e);
  //   dispatch(
  //     SearchCompany({
  //       name: search,
  //     })
  //   );
  // };
  useEffect(() => {
    dispatch(SearchCompany({name: search}))
  },[search])

  //filter
  const onSelect = (e) => {
    e.preventDefault(e);
    dispatch(
      FilterCompany({
        isoid: e.target.value,
      })
    );
  };

  useEffect(() => {
    if (window.innerWidth <= 767) return history.replace("/m/company")
  },[])


  useEffect(() => {
    if (!modal) setModal(mobileView)
  }, [mobileView])

  const _onFocus = () => { 
    setNameFocused(true)
    setStyle("input-company-active");
  };

  const _onBlur = () => {
    setNameFocused(false)
    setStyle("input-company");

  };


  return (
    <>
      <div className="main-page-category-company">

        {/* list location version desktop */}
        <div className="left-category-company">
          {/* <div style={{ marginLeft: -10 }}> */}
            <Navbar />
          {/* </div> */}
          <div style={{ marginTop: -20, zIndex: 2 }}>
            {/* <form className="mr-3" onSubmit={(e) => onSubmit(e)}> */}
            <CustomInput
              placeholder="Search Location"
              className={style}
              onFocus={_onFocus}
              onBlur={_onBlur}
              onChange={(e) => setSearch(e.target.value)}
            />
              {/* <HeaderNav   /> */}
            {/* </form> */}
            <Gap height={15} />
            <div className="ml-3 d-flex">
              <input
                className="mt-3 mr-2"
                type="checkbox"
                id="nearby"
                name="nearby"
                value="nearby"
                checked={nearby}
                onChange={showNearby}
              />
              <p className="mt-3 nearby">Nearby Location</p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: -35,
                marginRight: 20,
              }}
            >
              <select
                className="custom-select"
                name="iso"
                id="iso"
                style={{ width: "40%", fontSize: 12, marginBottom: 10 }}
                onChange={(e) => onSelect(e)}
              >
                <option disabled selected>
                  Filter
                </option>
                <option value="1">ISO 9001</option>
                <option value="2">ISO 22001</option>
                <option value="6">ISO 14001</option>
                <option value="7">ISO 45001</option>
                <option value="8">ISO 13485</option>
                <option value="10">ISPO</option>
              </select>
            </div>
            <Scrollbars style={{ height: "75vh" }}>
              {loadingCompany ? 
              <Skeleton count={2} width={200}/> :
              <>
              {nearby
                ? data.map((items) => {
                    const lat1 = location.lat;
                    const lon1 = location.lng;
                    const lat2 = parseFloat(items.latitude);
                    const lon2 = parseFloat(items.longitude);
                    const R = 6371e3; // earth radius in meter

                    const φ1 = lat1 * (Math.PI / 180);
                    const φ2 = lat2 * (Math.PI / 180);
                    const Δφ = (lat2 - lat1) * (Math.PI / 180);
                    const Δλ = (lon2 - lon1) * (Math.PI / 180);

                    const a =
                      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                      Math.cos(φ1) *
                        Math.cos(φ2) *
                        (Math.sin(Δλ / 2) * Math.sin(Δλ / 2));

                    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                    let markerPlace = [];
                    const distance = (R * c) / 1000;
                    markerPlace.push(distance);
                    if (markerPlace < 20) {
                      return (
                        <CardItem
                          onClick={() =>
                            history.push(`/detail_place/${items.map_id}`)
                          }
                          key={items.map_id}
                          name={items.name}
                          location={items.location}
                          // images={items.photo}
                          rating={items.rating}
                          facility={items.facility}
                          map_id={items.map_id}
                        />
                      );
                    }
                  })
                : data.map((items) => {
                    return (
                      <CardItem
                        onClick={() =>
                          history.push(`/detail_place/${items.map_id}`)
                        }
                        key={items.map_id}
                        name={items.name}
                        location={items.location}
                        images={items.photo}
                        rating={items.rating}
                        facility={items.facility}
                        map_id={items.map_id}
                      />
                    );
                  })}
                </>
              }
            </Scrollbars>
          </div>
        </div>

        {/* map */}
        <div className="right-category-company">
          <div className="maps-category-company">
            <MapCompany map_id={data.map_id} nearby={nearby} />
          </div>
        </div>

      </div>
    </>
  );
};

export default Company;
