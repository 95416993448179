import { combineReducers } from "redux";
import GetPlace from "./getPlace";
import GetHotel from "./getHotel";
import GetSPBU from "./getSPBU";
import GetById from "./getById";
import GetCompany from "./getCompany";
import GetTotalPerusahaan from "./getTotalPerusahaan";
import GetTotalPerusahaanHighChart from "./getTotalPerusahaanHighChart";
import GetTotalPerusahaanPieChart from "./getTotalPerusahaanPieChart";
import GetMasterSertifikat from "./getMasterSertifikat";
import GetMasterProvinsi from "./getMasterProvinsi";
// import GetTotalPerusahaanTersertifikasiAktif from "./getTotalPerusahaanTersertifikasiAktif";
// import GetTotalPerusahaanTersertifikasiTidakAktif from "./getTotalPerusahaanTersertifikasiTidakAktif";
// import GetTotalPerusahaanBelumBersertifikat from "./getTotalPerusahaanBelumBersertifikat";


const reducer = combineReducers({
  GetPlace,
  GetHotel,
  GetSPBU,
  GetById,
  GetCompany,
  GetTotalPerusahaan,
  GetTotalPerusahaanHighChart,
  GetTotalPerusahaanPieChart,
  GetMasterSertifikat,
  GetMasterProvinsi
  // GetTotalPerusahaanTersertifikasiAktif,
  // GetTotalPerusahaanTersertifikasiTidakAktif,
  // GetTotalPerusahaanBelumBersertifikat,
});

export default reducer;
