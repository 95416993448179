import React, { Fragment, useState, useEffect } from "react";
import { Navbar, useGeolocation } from "../../component";
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
  Circle,
} from "react-google-maps";
import axios from "axios";
import { 
  URI, 
  styleMap, 
  markerUser, 
  markerHotel, 
  markerRestaurant, 
  markerSPBU, 
  markerCompany, 
  googleMapURL } from "../../utils";

const Dashboard = (props) => {

  //get user location(GPS)
  const location = useGeolocation();
  const [count, setCount] = useState('');

  //radius circle
  const options = {
    strokeColor: "#AFEEEE",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "#AFEEEE",
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 25000,
  };

  useEffect(()=> {
    axios.get(URI)
    .then(result => {
        const ResponseAPI = result.data
        // console.log(result.data)
        setCount(ResponseAPI)
    })
    .catch(err => {
        console.log('error: ', err)
    })
  },[])

  const MarkerColor = () => {

    
  }

  //variabel for map
  const MapWrapped = withScriptjs(
    withGoogleMap(() => (
      <GoogleMap
        defaultZoom={11}
        defaultCenter={{
          lat: location.coordinates.lat,
          lng: location.coordinates.lng,
        }}
        defaultOptions={{
          styles: styleMap,
        }}
      >
        <Fragment>
          {/* marker user location */}
          <Marker
            position={{
              lat: parseFloat(location.coordinates.lat),
              lng: parseFloat(location.coordinates.lng),
            }}
            icon={{
              url: markerUser,
              scaledSize: new window.google.maps.Size(35, 35),
            }}
          />

          {/* radius circle */}
          {/* <Circle
            center={{
              lat: parseFloat(location.coordinates.lat),
              lng: parseFloat(location.coordinates.lng),
            }}
            options={options}
          /> */}

          {/* determine the color according to each category */}
          {count.response ? count.response.map(items => {
            // console.log(parseFloat(items.latitude), parseFloat(items.longitude))
            // return <p>{items.latitude, items.longitude}</p>
            if(items.catmap_id === "2") {
              return (
                <Marker
                  position={{
                    lat: parseFloat(items.latitude),
                    lng: parseFloat(items.longitude),
                  }}
                  icon={{
                    url: markerHotel,
                    scaledSize: new window.google.maps.Size(25, 35),
                  }}
                />
              )
            } else if(items.catmap_id === "1") {
              return (
                <Marker
                  position={{
                    lat: parseFloat(items.latitude),
                    lng: parseFloat(items.longitude),
                  }}
                  icon={{
                    url: markerRestaurant,
                    scaledSize: new window.google.maps.Size(25, 35),
                  }}
                />
              )
            } else if(items.catmap_id === "3") {
              return (
                <Marker
                  position={{
                    lat: parseFloat(items.latitude),
                    lng: parseFloat(items.longitude),
                  }}
                  icon={{
                    url: markerSPBU,
                    scaledSize: new window.google.maps.Size(25, 35),
                  }}
                />
              )
            } else {
              return (
                <Marker
                  position={{
                    lat: parseFloat(items.latitude),
                    lng: parseFloat(items.longitude),
                  }}
                  icon={{
                    url: markerCompany,
                    scaledSize: new window.google.maps.Size(25, 35),
                  }}
                />
              )
            }
          }): null}
        </Fragment>
      </GoogleMap>
    ))
  );

  return (
    <>
      <div className="col-lg-12 row d-flex" style={{ overflow: "hidden" }}>
        <div style={{ position: "relative", zIndex: 99, marginTop: 70 }}>
          <Navbar />
        </div>
        <div style={{ position: "relative", zIndex: 1, marginTop: -150 }}>
          <div style={{ width: "100vw", height: "100vh" }}>
            <MapWrapped
              // map_id={data.map_id}
              googleMapURL={googleMapURL}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
